import React, { useState } from 'react'
import './style.scss'
import Axios from 'axios'
import Icon from 'react-icons-kit'
import { toast } from 'react-toastify'
import { api } from '../../../utils/api'
import { plus } from 'react-icons-kit/feather'
import 'react-toastify/dist/ReactToastify.css'
import { useQuery } from "react-query";

import GhostTable from '../../../components/ghostLoader/table/Index'
import NoticeList from '../../../components/noticeBoard/noticeList/Index'
import CreateNoticeModal from '../../../components/noticeBoard/modal/Cretate'
import ShowNoticeModal from '../../../components/noticeBoard/modal/Show'
import EditNoticeModal from '../../../components/noticeBoard/modal/Edit'
import DeleteNoticeModal from '../../../components/modal/delete/Index'

toast.configure({ autoClose: 2000 })
const Index = () => {
    const [isLoading, setLoading] = useState(true)
    const [isCreate, setCreate] = useState({ show: false, loading: false })
    const [showNotice, setShowNotice] = useState({ show: false, data: null })
    const [editNotice, setEditNotice] = useState({ show: false, data: null, loading: false })
    const [deleteNotice, setDeleteNotice] = useState({ show: false, data: null, loading: false })
    const [header] = useState({headers: { Authorization: "Bearer " + localStorage.getItem('token') } })

    const getpageData = async () => {
        try {
            let pageData = { managers: [] }
            const [res, response] = await Promise.all([Axios.get(`${api}auth/indexofmanager`, header), Axios.get(`${api}notice/index`, header)])
            if (res.status === 200 && response.status === 200) {
                res.data.adminList.map(async (one, idx) => {
                    pageData.managers.push({ id: idx, value: `${one._id}`, label: `${one.name}-${one.email}` })
                })
                Object.assign(pageData, { "notices": response.data.notices })
                setLoading(false)
            }
            return pageData
        } catch (error) {
            if (error) toast.error(error.response.data)
        }
    }


    const managersANDnotices = useQuery("managersandnotices", getpageData, {
        retry: false,
        cacheTime: 600000,
        refetchOnWindowFocus: false,
        enabled: Boolean(isLoading),
    });
    
    // Cretae Notice
    const createNotice = async (data) => {
        try {
            setCreate({ ...editNotice, show: true, loading: true })
            const response = await Axios.post(`${api}notice/create`, data, header)
            if (response.status === 201) {
                toast.success(response.data.message)
                setCreate({ show: false, loading: false })
                setLoading(true)
            }
        } catch (error) {
            if (error) toast.error(error.response ? error.response.data.message : null)
        }
    }


    // Update Notice
    const updateNotice = async (data) => {
        try {
            setEditNotice({ ...editNotice, show: true, loading: true })

            const response = await Axios.put(`${api}notice/${data.id}/update`, data, header)
            if (response.status === 201) {
                toast.success(response.data.message)
                setEditNotice({ ...editNotice, show: false, loading: false })
                setLoading(true)
            }
        } catch (error) {
            if (error) toast.error(error.response ? error.response.data.message : null)
        }
    }


    // Delete Notice
    const doDeleteNotice = async () => {
        try {
            setDeleteNotice({ ...deleteNotice, loading: true })

            const response = await Axios.delete(`${api}notice/${deleteNotice.data}/delete`, header)
            if (response.status === 200) {
                toast.success(response.data.message)
                setDeleteNotice({ ...deleteNotice, loading: false, show: false })
                setLoading(true)
            }
        } catch (error) {
            if (error) toast.error(error.response ? error.response.data.message : null)
        }
    }

    if (managersANDnotices.isLoading) return <GhostTable />

    return (
        <div className="notice-board-index">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header p-4">
                                <div className="d-flex">
                                    <div><h5 className="mb-0 mt-1">Notice board</h5></div>
                                    <div className="ml-auto">
                                        <button
                                            type="button"
                                            className="btn rounded-circle shadow-none"
                                            onClick={() => setCreate({ show: true })}
                                        >
                                            <Icon icon={plus} size={22} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <NoticeList
                                    data={managersANDnotices.data.notices}
                                    show={(notice) => setShowNotice({ show: true, data: notice })}
                                    edit={(notice) => setEditNotice({ show: true, data: notice })}
                                    delete={(notice) => setDeleteNotice({ ...deleteNotice, data: notice, show: true })}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Create modal */}
            {isCreate.show ?
                <CreateNoticeModal
                    show={isCreate.show}
                    loading={isCreate.loading}
                    create={createNotice}
                    managers={managersANDnotices.data.managers}
                    onHide={() => setCreate({ show: false })}
                />
                : null}

            {/* Show modal */}
            {showNotice.show ?
                <ShowNoticeModal
                    show={showNotice.show}
                    data={showNotice.data}
                    onHide={() => setShowNotice({ show: false })}
                />
                : null}

            {/* Edit modal */}
            {editNotice.show ?
                <EditNoticeModal
                    show={editNotice.show}
                    data={editNotice.data}
                    loading={editNotice.loading}
                    update={updateNotice}
                    onHide={() => setEditNotice({ show: false })}
                />
                : null}

            {/* Delete modal */}
            {deleteNotice.show ?
                <DeleteNoticeModal
                    show={deleteNotice.show}
                    data={deleteNotice.data}
                    loading={deleteNotice.loading}
                    delete={doDeleteNotice}
                    onHide={() => setDeleteNotice({ ...deleteNotice, show: false })}
                />
                : null}

        </div>
    );
}

export default Index;